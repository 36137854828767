import type { IFileLink } from "@/assets/ts/interfaces/global/IFileLink";

export function getInfoFile(fileLinks: string[]): IFileLink[] {
	return fileLinks.map((item) => {
		const name: string = item.split("/").pop() as string;
		const ext = name.split(".").pop() as string;
		const link = item;

		return {
			name,
			ext,
			link,
		};
	});
}
